<template>
  <div class="game_details">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <router-link :to="{name:'AllGame'}">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;全部游戏
      </router-link>
      <router-link
        :to="this.$route.query.game_id == '26'?{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'yuanshen5'}}:{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'games'}}">
        &emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;{{ game_name }}
      </router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;账号详情</a>
    </div>
    <div v-if="can_show" class="details">
      <div class="details_info">
        <div class="DI_base_img">
          <div slot="reference">
            <el-image
              style="width: 520px"
              :style="accountInfo.id_api_shangjia?'':'height:290px'"
              :src="accountInfo.image"
              fit="cover"
              :preview-src-list="srcList">
              <div slot="placeholder" class="image-slot">
                图片加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </div>
        <div class="DI_base_content">
          <div class="DI_prodct_tip">
            温馨提示：工作时间9：30-00：30，官网首页“客服验证”核验客服身份
          </div>
          <div class="DI_prodct_price">
            <div id="DI_prodct_price" v-if="accountInfo.price > 0">
              商品价格：
              <div>¥{{ accountInfo.price }}</div>
              <img src="@/assets/image/AllGame/isSign.png" alt="">
            </div>
          </div>
          <div class="DI_prodct_zone">
            <div v-for="(categoryItem,index) in accountInfo.category" :key="index" id="DI_prodct_zone">
              <div class="zone_title">
                {{ categoryItem.name }}：
              </div>
              <div class="zone_content">{{ categoryItem.value }}</div>
            </div>
          </div>
          <div class="DI_prodct_promise">
            <div class="promise_title">
              服务承诺：
            </div>
            <div class="promise_list">
              <div class="promise_item">
                <img src="@/assets/image/AllGame/promise1.png" alt="">
                不玩回收
              </div>
              <div class="promise_item">
                <img src="@/assets/image/AllGame/promise2.png" alt="">
                找回赔偿
              </div>
              <div class="promise_item">
                <img src="@/assets/image/AllGame/promise3.png" alt="">
                玩腻换号
              </div>
              <div class="promise_item">
                <img src="@/assets/image/AllGame/promise4.png" alt="">
                商品可投保
              </div>
            </div>
          </div>
          <div class="DI_prodct_ohter">
            <div id="DI_prodct_hot">
              <img src="@/assets/image/Home/topGamesIco.png" alt="">
              <span>{{ accountInfo.click }}</span>
            </div>
            <div id="DI_prodct_time">
              上架时间：
              <span>{{ accountInfo.up_time|moment }}</span>
            </div>
          </div>
          <!--          <div class="DI_prodct_addDate">
                      <div class="times" v-if="accountInfo.game_id == 15">
                        <div v-if="accountInfo.acc_source == 0">
                          该账号首次在螃蟹平台出售
                        </div>
                        <div v-else-if="accountInfo.acc_source == 1">
                          该账号在螃蟹平台已有<span class="transactions_num">{{ accountInfo.transactions_num }}</span>次交易
                        </div>
                        <div v-else-if="accountInfo.acc_source == 2">
                          其他平台购买（入手满6个月）
                        </div>
                        <div v-else-if="accountInfo.acc_source == 3">
                          其他平台购买（入手未满6个月）
                        </div>
                      </div>
                      <div class="up_time">
                        上架时间：
                        <span>{{ accountInfo.up_time|moment }}</span>
                      </div>
                    </div>-->
        </div>
      </div>
      <div class="DI_btnGroup">
        <div class="DI_btnGroup_left">
<!--          <div v-if="!accountInfo.is_collect" @click="collectClick" class="collectBtn" @mouseleave="collecIcon='pxb7-star'" @mouseenter="collecIcon='pxb7-star-full'">
            <i class="iconfont" :class="collecIcon"></i>
            点击收藏
          </div>
          <div v-else @click="collectClick" class="isCollectBtn">
            <i class="iconfont pxb7-star-full"></i>
            已收藏
          </div>-->
          <share :game_id="game_id" :game_name="game_name" :name="accountInfo.name" :id="id" ></share>
        </div>
        <div class="DI_btnGroup_right">
<!--          <div class="priceCutBtn"  @click="seeking_price_cuts_confirm">
            求降价
          </div>-->
<!--          <a v-if="game_id==10" @click="cusSerBtnClicks" class="onlineDealBtn">-->
<!--            立即购买-->
<!--          </a>-->
<!--          <a v-else @click="cusSerBtnClick" class="onlineDealBtn">-->
<!--            立即购买-->
<!--          </a>-->
          <a href="https://shop523300960.taobao.com/" class="onlineDealBtn" target="https://shop523300960.taobao.com/">
            立即购买
          </a>
          <!--        <router-link :to="{name:'OnlineDeal',query:{id:id,game_id:game_id}}" class="onlineDealBtn">立即购买</router-link>-->

<!--          <div v-if="game_id==10" @click.stop="cusSers()" class="cusSerBtn">-->
<!--            <img src="@/assets/image/AllGame/gameDetails/cusSerIcon.png" alt="">-->
<!--            咨询客服-->
<!--          </div>-->
<!--          <div v-else @click.stop="cusSer()" class="cusSerBtn">-->
<!--            <img src="@/assets/image/AllGame/gameDetails/cusSerIcon.png" alt="">-->
<!--            咨询客服-->
<!--          </div>-->
        </div>


      </div>
      <div class="DI_productName">
        <span :class="accNameHide?'DI_productName_hide':'DI_productName_show'" ref="detailDom">
          <span class="DI_productName_title">{{ accountInfo.game_name }}</span>
          {{ accountInfo.name }}
        </span>
        <div class="DI_productName_btn" v-if="showDetailBtn" @click="accNameHide = !accNameHide">
          {{ accNameHide ? '展开全部' : '收起文字' }} <i :class="accNameHide?'el-icon-caret-bottom':'el-icon-caret-top'"></i>
        </div>
      </div>
      <div class="DI_links">
        <div class="labels">
          <router-link :to="{name:'TradeTip',query:{id:'110',parent_id:'83'}}">
            分期购
          </router-link>
          <a>
            账号安全解析
          </a>
          <router-link :to="{name:'TradeTip',query:{id:'112',parent_id:'83'}}">
            投诉建议
          </router-link>
          <router-link :to="{name:'Legal',query:{id:'15',parent_id:'13'}}">
            找回包赔
          </router-link>
          <!-- <el-popover v-if="this.game_id == 15" placement="left" width="600" trigger="click">
            <el-image :src="require('../../../assets/image/AllGame/qqsg.jpg')"></el-image>
            <span slot="reference">免费估价</span>
          </el-popover> -->
          <a v-if="game_id != 15 && game_id == 10" href="#" @click="cusSers()">

            免费估价
          </a>
          <a v-if="game_id != 15 && game_id !=10" :href="accountInfo.game_customer" target="_blank">
            免费估价
          </a>
        </div>
      </div>
      <div class="details_tabbar">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="账号详情" name="first">
<!--            <div v-if="game_id == '15'" class="Tip15">
              <img v-if="category15Img" :src="category15Img" alt="">
              <img v-else src="@/assets/image/AllGame/emegencyNotice2.png" alt=""
                   @click="emegencyNotice2Click" style="cursor: pointer">
            </div>

            <div v-else-if="game_id == '10'" class="Tip">
              <img src="@/assets/image/AllGame/JL_tip.png" alt="" class="JL_tip">
              <img src="@/assets/image/AllGame/JL_tipBtn.png" alt="" class="JL_tipBtn"
                   @click="gotoLink('https://jq.qq.com/?_wv=1027&k=bpmHpZUb')">
            </div>
            <div class="Tip12" v-else-if="game_id == '12'">
              <img src="@/assets/image/AllGame/YXLM_tip.png" alt="" class="JL_tip">
            </div>
            <div class="Tip12" v-else-if="game_id == '26'">
              <img src="@/assets/image/AllGame/YS_tip.png" alt="" class="JL_tip">
            </div>
            <div class="Tip12" v-else-if="game_id == '13'">
              <img src="@/assets/image/AllGame/WZRY_tip.png" alt="" class="JL_tip">
            </div>
            <div class="Tip12" v-else-if="game_id == '45'">
              <img src="@/assets/image/AllGame/SLDFZ_tip.png" alt="" class="JL_tip">
            </div>
            <img v-else src="@/assets/image/AllGame/emegencyNotice.png" alt="">-->
            <div class="first_content ql-container ql-snow">
              <p class=" ql-editor" v-html="accountInfo.content"></p>
            </div>
            <div class="first_content ql-container ql-snow">
              <p class=" ql-editor" v-html="accountInfo.cdata"></p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="装备包赔" name="sixth" v-if="accountInfo.game_reparation">
            <div class="third_content">
              <div v-html="accountInfo.game_reparation"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="交易须知" name="third">
            <div class="third_content">
              <!--              <div v-html="accountInfo.game_notice"></div>-->
<!--              <img v-if="this.$route.query.game_id == '25'" src="@/assets/image/AllGame/Tran_Notice25.png" alt="">-->
<!--              <img v-else-if="this.$route.query.game_id == '28'" src="@/assets/image/AllGame/Tran_Notice28.png" alt="">-->
              <!-- <img v-else-if="this.$route.query.game_id == '12'" src="@/assets/image/AllGame/Tran_Notice12.png" alt="">
              <img v-else-if="this.$route.query.game_id == '40'" src="@/assets/image/AllGame/Tran_Notice40.png" alt=""> -->
              <img  src="@/assets/image/AllGame/Tran_Notice.png" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane label="包赔介绍" name="fifth">
            <div class="third_content">
              <div v-html="accountInfo.game_introduce"></div>
            </div>
          </el-tab-pane>
<!--          <el-tab-pane label="交易群" name="fourth">
            <div class="fourth_content">
              <div class="fourth_content_item" v-for="(item,index) in qqGrounp" :key="index">
                <div class="fourth_content_item_box">
                  <div>
                    <img src="@/assets/image/AllGame/contentItemTitle.png" alt="">
                  </div>
                  <div class="qq_logo">
                    <img src="@/assets/image/AllGame/QQlogo.png" alt="">
                  </div>
                  <div class="group_name">{{ item.name }}</div>
                  <a class="add_group" target="_blank" :href="item.link">点击加群</a>
                </div>
              </div>
            </div>
          </el-tab-pane>-->
        </el-tabs>
      </div>
    </div>
    <div v-else class="showFalse">
      <div class="showFalse_tip">
        <img src="@/assets/image/PersonalCenter/Seller/noData.png" alt="">
<!--        <img src="@/assets/image/AllGame/showFalse.png" alt="">-->
      </div>
      <div class="showFalse_btnG">
        <router-link id="back" :to="{name:'Home'}">返回首页</router-link>
        <a v-if="game_id == 10" class="cusSer" href="#" @click="cusSers()">联系客服</a>
        <a v-else class="cusSer" target="_blank" :href="accountInfo.game_customer">联系客服</a>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      custom-class="contactCusSer"
      :show-close="false"
      width="500px"
      top="35vh"
      center>
          <span>
            <div id="contactCusSer">
              <div class="contactCusSer_title">
                请添加微信号或QQ号联系客服
              </div>
              <a target="_blank" @click="$accessLink(accountInfo.game_customer)"
                 :href="`${accountInfo.game_customer}?u_cust_id=${$store.state.userData.user_id}&u_cust_name=${$store.state.userData.nickname}`"
                 class="onlineCus">在线客服</a>
              <div class="contactCusSer_tip">友情提示：在线客服聊天更顺畅！</div>
              <div class="contactCusSer_tip">
                1、QQ客服跳转失败，请复制QQ号手动<span>添加好友</span>！
              </div>
              <div class="contactCusSer_tip">
                2、我们有可能无法接收到您的QQ信息，请点击在线客服联系！
              </div>
              <div class="contactCusSer_tip">
                3、在线客服聊天更顺畅！
              </div>
            </div>
          </span>
    </el-dialog>
    <mydialog :text="'尊敬的VIP您好，根据相关法律法规要求，需实名认证方可进行购买。'" :btn-text="'去实名'" btn :show="realNameShow"
              :close-fun="mydialogClose" :btn-fuc="mydialogBtnClick"
    ></mydialog>
    <left-product v-if="game_id == 26" :product_id="id" :game_id="game_id"></left-product>
  </div>
</template>

<script>
import {
  apiProductInfo,
  apiCollectSet,
  apiBargainAdd,
  apiBargainClcik,
  apiCustomerRandom,
  apiHistoryCusAdd,
  apiHistoryProductAdd,
  apiuserInfo
} from "@/request/API";
import LeftProduct from "@/components/leftProduct";
import mydialog from "@/components/myDialog";
import share from "@/components/share"

export default {
  name: '',
  components: {
    LeftProduct,
    mydialog,
    share
  },
  data() {
    return {
      //游戏名称
      game_name: '',
      //游戏id
      game_id: this.$route.query.game_id,
      //账号id
      id: this.$route.query.id,
      // 当前选中tabbar
      activeName: 'first',
      // 交易qq群
      qqGrounp: [],
      //账号详情
      accountInfo: {
        image: ''
      },
      can_show: true,
      srcList: [],
      centerDialogVisible: false,
      //客服微信列表
      CS_wechat_List: [
        // {name:'天天',type:'微信',wechat:'pxb7tt'},
        // {name:'可乐',type:'微信',wechat:'pxb7kl'},
        // {name:'喵喵',type:'微信',wechat:'pxb7mm'},
        // {name:'奶茶',type:'微信',wechat:'pxb7nc'},
      ],
      //账号标题隐藏
      accNameHide: true,
      showDetailBtn: false,
      detailDom: '',
      //qq三国 按服务器
      category15: '',
      category15Img: '',
      //立即购买 实名弹窗
      realNameShow: false,
      //收藏图标
      collecIcon:'pxb7-star',
      //收藏图标
      shareIcon:'pxb7-share',
    }
  },
  methods: {
    cusSers() {
      let routeData = this.$router.resolve({
        name: 'CustomerSer',
        query: {
          game_id: this.game_id
        }
      })
      window.open(routeData.href, '_blank')
    },
    //在线交易
    onlineDeal(){
      this.$router.push({
        name: 'OnlineDeal',
        query: {
          id: this.id,
          game_id: this.game_id
        }
      })

    },
    async cusSerBtnClicks() {
      if (this.$store.state.loginStatus) {
        let userInfo = await apiuserInfo({action: 'verified'})
        if (userInfo.data.cert_id === '') {
          this.realNameShow = true
          return
        }
        // this.cusSers()
        this.onlineDeal()
      } else {
        this.$message({
          type: "error",
          message: '尚未登录，请先登录。',
          center: true
        })
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.path,
            game_name: this.game_name,
            game_id: this.game_id,
            id: this.id,
          },
        })
      }
    },
    /**
     * @description: 跳转联系客服
     * @return {*}
     */
    async cusSerBtnClick() {
      if (this.$store.state.loginStatus) {
        let userInfo = await apiuserInfo({action: 'verified'})
        if (userInfo.data.cert_id === '') {
          this.realNameShow = true
          return
        }
        // this.cusSer()
        this.onlineDeal()
      } else {
        console.log(this.$route);
        this.$message({
          type: "error",
          message: '尚未登录，请先登录。',
          center: true
        })
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.fullPath,
            game_name: this.game_name,
            game_id: this.game_id,
            id: this.id,
          },
        })
      }
    },
    cusSer() {
      this.CS_wechat_List = []
      apiCustomerRandom({
        type: '1',
        game_id: this.game_id,
        business_id: '1',
      }).then(res => {
        let customer = new Object()
        customer.name = res.data.customer_name
        customer.wechat = res.data.customer_number
        customer.link = res.data.link
        this.CS_wechat_List.push(customer)
      })
      this.$accessLink(this.accountInfo.game_customer)
      this.historySerAdd()
      var api = $53.createApi()
      api.push('cmd', 'comeinfo');
      api.push('title', this.accountInfo.name);
      api.push('logo', this.accountInfo.image);
      api.push('content', `商品价格:${this.accountInfo.price}`);
      api.push('curl', location.href);
      api.query();
      api.push('cmd', 'kfclient');
      api.push('type', 'new');
      api.push('group_id', this.accountInfo.buy_group);
      api.query();
    },
    /**
     * 关闭实名认证提示框
     */
    mydialogClose() {
      this.realNameShow = false
    },
    mydialogBtnClick() {
      this.$router.push({
        path: '/personalCenter/personalInfo/rNameAuthentication',
        query: {
          Rurl: this.$route.fullPath,
        },
      })
    },
    //复制微信
    contactCusSer_copy(wechat) {
      var text = wechat;
      var input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行浏览器复制命令
      this.$message({
        type: "success",
        center: true,
        message: '复制成功'
      })
    },
    handleClick(tab, event) {
    },
    /**
     * 收藏按钮
     */
    collectClick() {
      if (this.$store.state.loginStatus) {
        apiCollectSet({
          game_id: this.game_id,
          acc_id: this.id,
        }).then(res => {
          this.accountInfo.is_collect = res.data.is_collect
        }).catch(err => {
          this.$message({
            type: "error",
            center: true,
            message: err.msg
          })
        })
      } else {
        this.$message({
          type: "error",
          message: '尚未登录，请先登录。',
          center: true
        })
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.fullPath,
            game_name: this.game_name,
            game_id: this.game_id,
            id: this.id,
          },
        })
      }
    },
    seeking_price_cuts_confirm() {
      apiBargainClcik({
        game_id: this.game_id,
        id: this.id,
      }).catch(err => {
        this.$message({
          type: "error",
          message: err.msg,
          center: true
        })
      })
      if (this.$store.state.loginStatus) {
        let sell_price = 'block'
        if (this.accountInfo.sell_price == '0.00') {
          sell_price = 'none'
        }
        const h = this.$createElement;
        if (this.accountInfo.is_bargain) {
          this.$msgbox({
            title: '',
            message: h('div', {class: 'confirm_content'}, [
              h('div', {class: 'confirm_original_price'}, '原价：' + this.accountInfo.price + ''),
              h('div', {class: 'confirm_my_bid'}, '我的出价：' + this.accountInfo.bargain_price + ''),
              h('div', {
                class: 'confirm_my_bid',
                style: "display:" + sell_price + ""
              }, '卖家最低报价：' + this.accountInfo.sell_price + ''),
              h('div', {class: 'confirm_modify_bid'}, [
                h('div', {class: 'confirm_modify_bid_text'}, '修改出价：'),
                h('input', {
                  class: 'confirm_modify_bid_input',
                  attrs: {placeholder: "输入修改后的价格", id: "confirm_modify_bid_input"}
                }),
              ]),
              h('div', {class: 'confirm_modify_bid_tips'}, '（提示：24小时内只能求降价1次）'),
            ]),
            confirmButtonText: '确认修改',
            confirmButtonClass: 'change_price_confirm_btn',
            customClass: 'change_price_confirm',
            closeOnClickModal: false,
            showClose: true,
            callback: action => {
              if (action === "confirm") {
                apiBargainAdd({
                  id: this.accountInfo.bargain_id,
                  price: document.getElementById("confirm_modify_bid_input").value
                }).then(res => {
                  this.$message({
                    message: "修改成功",
                    center: true,
                    type: 'success'
                  })
                }).catch(err => {
                  this.$message({
                    message: err.msg,
                    center: true,
                    type: 'error'
                  })
                })
              }
              document.getElementById("confirm_modify_bid_input").value = ''
            }
          });
        } else {
          this.$msgbox({
            title: '',
            message: h('div', {class: 'confirm_content'}, [
              h('div', {class: 'confirm_original_price'}, '原价：' + this.accountInfo.price + ''),
              h('div', {class: 'confirm_modify_bid'}, [
                h('div', {class: 'confirm_modify_bid_text'}, '我的出价：'),
                h('input', {
                  class: 'confirm_modify_bid_input',
                  attrs: {placeholder: "输入我出的价格", id: 'confirm_modify_bid_input'}
                }),
              ]),
              h('div', {class: 'confirm_modify_bid_tips'}, '（提示：24小时内只能求降价1次）'),
            ]),
            confirmButtonText: '确认发起',
            confirmButtonClass: 'change_price_confirm_btn',
            customClass: 'add_price_confirm',
            closeOnClickModal: false,
            showClose: true,
            callback: action => {
              if (action === "confirm") {
                apiBargainAdd({
                  game_id: this.game_id,
                  product_id: this.id,
                  price: document.getElementById("confirm_modify_bid_input").value
                }).then(res => {
                  this.$message({
                    center: true,
                    message: "发起降价请求成功",
                    type: 'success'
                  })
                  this.accountInfo.is_bargain = true
                  this.accountInfo.bargain_price = res.data.bargain_price

                }).catch(err => {
                  this.$message({
                    message: err.msg,
                    center: true,
                    type: 'error'
                  })
                })
              }
              document.getElementById("confirm_modify_bid_input").value = ''
            }
          });
        }
      } else {
        this.$message({
          type: "error",
          message: '尚未登录，请先登录。',
          center: true
        })
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.fullPath,
            game_name: this.game_name,
            game_id: this.game_id,
            id: this.id,
          },
        })
      }
    },
    //初始化数据
    onLoad() {
      apiProductInfo({
        game_id: this.game_id,
        id: this.id,
      }).then(res => {
        this.can_show = res.data.can_show
        this.accountInfo = res.data
        this.srcList.push(res.data.image)
        this.game_name = res.data.game_name
        this.qqGrounp = res.data.game_qun
        if (res.data.game_id == '15') {
          this.accountInfo.category.forEach(item => {
            if (item.name == '按服务器') {
              this.category15Img = require(`../../../assets/image/AllGame/gameDetailsTip15/广告-5 - ${item.value}.jpg`)
            }
          })
        }
        document.title = this.accountInfo.name
        this.showDetailBtnFun();
        if (res.data.game_id == '26') {
          res.data.category = [...res.data.category, ...[
            {name: '绑定TAP', value: res.data.bind_tap === 1 ? '是' : '否'},
            {name: '绑定PSN', value: res.data.bind_psn === 1 ? '是' : '否'}
          ]]
        }
      })

    },
    /**
     * 跳转链接
     * @param link 链接
     */
    gotoLink(link) {
      window.open(link)
    },
    /**
     * qq三国装备跳转
     */
    emegencyNotice2Click() {
      this.$router.push({
        path: 'selectGame',
        name: 'SelectGame',
        query: {game_id: '15', game_alias: 'games'},
        params: {game_name: 'QQ三国', game_class: '网路游戏', game_id: '15', productTypeSelect: 2}
      })
    },
    /**
     * 判断文本是否溢出
     * 特殊：如果文字长度刚好达到某个临界值，会出现不及时刷新出现按钮的情况，解决方案：使用this.$nextTick强制刷新
     */
    showDetailBtnFun() {
      this.$nextTick(() => {
        this.detailDom = this.$refs.detailDom;
        this.showDetailBtn = this.detailDom.clientHeight < this.detailDom.scrollHeight;
      })
    },
    /**
     * 商品浏览记录添加
     */
    historyProductAdd() {
      apiHistoryProductAdd({
        game_id: this.game_id,
        product_id: this.id,
        product_type: 1
      })
    },
    /**
     * 商品客服咨询记录添加
     */
    historySerAdd() {
      apiHistoryCusAdd({
        game_id: this.game_id,
        product_id: this.id,
        product_type: 1
      })
    },
  },
  mounted() {
    //初始化数据
    this.onLoad()
    this.historyProductAdd()
    this.detailDom = this.$refs.detailDom;
    this.showDetailBtnFun();
    window.addEventListener('resize', this.showDetailBtnFun);
  },
  watch: {},
  computed: {},
  created() {
    document.title = ''
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'GameDetails') {
      document.title = 'uushouyou网络游戏账号交易代售平台-签署合同让交易享受法律保护'
      next()
    }
  }


}
</script>

<style lang="less">
.first_content img, .second_content img, .third_content img {
  max-width: 100%;
  object-fit: cover;
  background: #D8D8D8;
  border-radius: 20px;
  margin: 0 0 9px 0;
}

.add_price_confirm {
  width: 500px !important;
  height: 300px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;

  .el-message-box__header > .el-message-box__title {
    display: none;
  }

  .el-message-box__header, .el-message-box__content {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 70px;
  }

  .el-message-box__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .el-message-box__title {
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }

  .el-message-box__container {
    margin-top: 63px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }

  .change_price_confirm_btn {
    margin-top: 20px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }

  .change_price_confirm_btn:hover {
    background: #FFDB51 !important;
  }

  .confirm_content {
    display: flex;
    flex-direction: column;
  }

  .confirm_original_price {
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FC4A00;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_my_bid {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_modify_bid {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .confirm_modify_bid_text {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
  }

  .confirm_modify_bid_input {
    width: 210px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    line-height: 22px;
  }

  .confirm_modify_bid_tips {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #BFBFBF;
    line-height: 22px;
    margin-left: 100px;
  }

  .confirm_modify_bid_input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #D1D1D1;
  }
}

.change_price_confirm {
  width: 500px !important;
  height: 320px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;

  .el-message-box__header > .el-message-box__title {
    display: none;
  }

  .el-message-box__header, .el-message-box__content {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 70px;
  }

  .el-message-box__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .el-message-box__title {
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }

  .el-message-box__container {
    margin-top: 34px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }

  .change_price_confirm_btn {
    margin-top: 20px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }

  .change_price_confirm_btn:hover {
    background: #FFDB51 !important;
  }

  .confirm_content {
    display: flex;
    flex-direction: column;
  }

  .confirm_original_price {
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FC4A00;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_my_bid {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_modify_bid {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .confirm_modify_bid_text {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
  }

  .confirm_modify_bid_input {
    width: 210px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    line-height: 22px;
  }

  .confirm_modify_bid_tips {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #BFBFBF;
    line-height: 22px;
    margin-left: 100px;
  }

  .confirm_modify_bid_input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #D1D1D1;
  }
}

.contactCusSer {
  width: 500px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  overflow: visible;

  .el-dialog__body {
    padding: 10px 15px 20px;
    position: relative;

    #contactCusSer {
      width: 470px;

      .contactCusSer_title {
        background: #313131;
        color: #FFFFFF;
        text-align: center;
        border-radius: 10px;
        height: 33px;
        line-height: 33px;
        font-size: 20px;
      }

      .contactCusSer_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;

        .CL_item {
          width: 450px;
          height: 26px;
          margin: 0 0 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          div {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #000000;
          }

          .CL_btn {
            width: 120px;
            display: flex;
            flex-direction: row;

            div, a {
              margin: 0 10px 0 0;
              font-size: 16px;
              background: rgb(255, 219, 81);
              width: 54px;
              height: 26px;
              line-height: 26px;
              text-align: center;
              border-radius: 7px;
              cursor: pointer;
            }

            a {
              margin: 0;
              background: #f19958;
            }

          }

          #input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -10;
          }
        }
      }

      .onlineCus {
        margin: 20px auto;
        width: 100px;
        text-align: center;
        background-color: rgb(255, 219, 81);
        color: black;
        padding: 10px 18px;
        font-weight: bold;
        font-size: 16px;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        display: block;
      }

      .contactCusSer_tip {
        text-align: center;
        color: black;
        margin: 4px 0 0 0;
        //font-weight: 600;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 14px;

        span {
          display: inline-block;
          color: #FF5454;
        }
      }
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
</style>

<style lang="less" scoped>
.ql-container {
  border: none !important;
}

/deep/ .el-tabs__nav-wrap::after {
  content: none;
}

/deep/ .el-tabs__nav-scroll, /deep/ .el-tabs__nav {
  height: 71px;
  display: flex;
  align-items: flex-start;
}

/deep/ .el-tabs__item {
  height: 71px;
  font-size: 20px;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 300;
  color: #000000;
  line-height: 60px;
  padding: 0 25px !important;
}

/deep/ .el-tabs__header {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15) !important;
}

/deep/ .el-tabs__item.is-active {
  background: url("../../../assets/image/AllGame/active-barBG.png") no-repeat;
  background-size: 100% 100%;

  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;

  line-height: 60px;
}

/deep/ .el-tabs__active-bar.is-top {
  //background: #FFFFFF linear-gradient(90deg, transparent 20px, #FCAD13 20px, #FCAD13 calc(100% - 20px), transparent calc(100% - 20px));
  ////width: 20% !important;
  //padding: 0 20px;
  //box-sizing: border-box;
  //background-clip: content-box;
  ////background: #FCAD13;
  //border-radius: 4px;

  height: 0px;
}

/deep/ .el-tabs__header {
  margin: 0 0 40px;
  padding: 0 40px;
  position: relative;
  height: 60px;
}

/deep/ .el-tabs__content {
  padding: 0px 40px 58px 40px;
}

.game_details {
  width: 1200px;

  .title {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;

    a {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;

      .Breadcrumb {
        font-size: 12px;
      }
    }

    a:last-of-type {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #434ED6 ;
      cursor: pointer;
    }
  }

  .details {
    display: flex;
    flex-direction: column;

    .details_info {
      padding: 20px 40px;
      background-color: #FFFFFF;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;

      .DI_base_img {
        margin: 0 20px 0 0;
        overflow: hidden;
        height: 290px;
        border-radius: 10px;
      }

      .DI_base_content {
        width: 580px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .DI_prodct_tip {
          height: 32px;
          background: #FFF0F0;
          padding: 0 20px;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #FF7272;
          line-height: 32px;
        }

        .DI_prodct_price {
          height: 62px;
          padding: 0 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          #DI_prodct_price {
            display: flex;
            align-items: center;
            flex-direction: row;
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            margin: 0 10px 0 0;

            div {
              font-size: 30px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FC4A00;
              margin: 0 10px 0 0;
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

        }

        .DI_prodct_zone {
          min-height: 50px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          //margin: 10px 0;
          padding: 0 20px;

          #DI_prodct_zone {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;

            .zone_title {
              max-width: 84px;
              min-width: 56px;
              font-size: 14px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #8F8F8F;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .zone_content {
              width: 118px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              background: url("../../../assets/image/AllGame/gamingZone.png") no-repeat;
            }
          }
        }

        .DI_prodct_promise {
          padding: 10px 0 11px 20px;
          border-top: 1px solid #F1F1F1;
          border-bottom: 1px solid #F1F1F1;
          display: flex;
          flex-direction: row;
          align-items: center;

          .promise_title {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            line-height: 20px;
            margin: 0 16px 0 0;
          }

          .promise_list {
            display: flex;
            flex-direction: row;

            .promise_item {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #BFBFBF;
              margin: 0 40px 0 0;

              img {
                margin: 0 4px 0 0;
              }
            }

            .promise_item:last-of-type {
              margin: 0;
            }
          }
        }

        .DI_prodct_ohter{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 26px 0 20px;
          #DI_prodct_hot{
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFang SC-Thin, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
            img{
              width: 21px;
              height: 13px;
              margin: 0 5px 0 0;
            }
          }
          #DI_prodct_time{
            font-size: 14px;
            font-family: PingFang SC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            span{
              color: #5B5B5B;
              margin: 0 0 0 11px;
            }
          }
        }

        .DI_prodct_addDate {
          margin: 11px 0 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .times {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #547990;
            padding: 10px 40px 10px 20px;
            background: #E5F5FF;
            border-radius: 10px;
            position: relative;

            .transactions_num {
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FC4A00;
              line-height: 28px;
            }
          }

          .times::before {
            position: absolute;
            content: '';
            z-index: 1;
            width: 0;
            height: 0;
            top: 14px;
            left: -14px;
            border-top: 10px solid transparent;
            border-right: 20px solid #E5F5FF;
            border-bottom: 10px solid transparent;
          }

          .up_time {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;

            span {
              color: #5B5B5B;
            }
          }

        }
      }
    }

    .DI_btnGroup {
      width: 1120px;
      height: 60px;
      background: #FFFFFF;
      padding: 8px 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .DI_btnGroup_left {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 520px;

        .collectBtn, .isCollectBtn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 40px;
          border-radius: 4px;
          margin: 0 40px 0 0;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #818181;

          i {
            margin: 0 10px 0 0;
            height: 30px;
            font-size: 30px;
            object-fit: contain;
            color: #FFB320 ;
          }
        }

        div {
          cursor: pointer;
        }
      }

      .DI_btnGroup_right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        //justify-content: space-between;
        width: calc(100% - 540px);

        div, a {
          margin: 0 5px;
          cursor: pointer;
          width: 186px;
          height: 60px;
          border-radius: 5px 5px 5px 5px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 60px;
          text-align: center;
        }
        .priceCutBtn{
          background: #434ED6;
        }
        .priceCutBtn:hover{
          background: #737EFC;
        }
        .onlineDealBtn {
          background: #F5354F;
        }
        .onlineDealBtn:hover {
          background: #FF6378;
        }

        .cusSerBtn{
          width: 184px;
          height: 58px;
          border: 1px solid #434ED6;
          background: #E7E8FF;
          color: #434ED6;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .cusSerBtn:hover{
          background: #F1F2FF;
        }
      }
    }

    .DI_productName {
      margin: 10px 0;
      width: 1120px;
      background: #FFFFFF;
      padding: 20px 40px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #5B5B5B;
      line-height: 34px;
      text-align: justify; /*实现两端对齐*/
      text-justify: newspaper; /*通过增加或减少字或字母之间的空格对齐文本*/
      word-break: break-all; /*允许在单词内换行*/

      .DI_productName_hide, .DI_productName_show {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;

        .DI_productName_title {
          border: 1px solid #434ED6;
          border-radius: 20px;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #434ED6;
          padding: 2px 13px;
          margin-right: 5px;
        }
      }

      .DI_productName_show {
        display: block;
      }

      .DI_productName_btn {
        margin: 15px 0 0;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #CACACA;
        line-height: 22px;
      }

      .DI_productName_btn:hover {
        color: #434ED6;
      }
    }

    .DI_links {
      width: 1120px;
      height: 40px;
      background: #FFFFFF;
      padding: 20px 40px;
      margin: 0 0 10px 0;

      .labels {
        display: flex;
        flex-direction: row;

        a, ::v-deep.el-popover__reference {
          flex: 1;
          text-align: center;
          margin: 0 11px 0 0;
          font-size: 16px;
          padding: 11px 10px;
          border-radius: 10px;
          border: 1px solid #ababab;

          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #868686;
        }

        & > span {
          margin-left: 11px;
          cursor: pointer;
          flex: 1;
          display: flex;
        }

        ::v-deep.el-popover__reference-wrapper {
          flex: 1;
          display: flex
        }

        ::v-deep.el-popover__reference:hover {
          color: #434ED6;
          background: #E4E4FF;
          border: 1px solid #434ED6;
        }

        a:last-of-type {
          margin: 0;
        }

        a:hover {
          color: #434ED6;
          background: #E4E4FF;
          border: 1px solid #434ED6;
        }
      }
    }

    .details_tabbar {
      //padding: 40px 40px 58px 40px;
      //padding: 0px 40px 58px 40px;
      background-color: #FFFFFF;

      .Tip {
        position: relative;

        .JL_tip {

        }

        .JL_tipBtn {
          position: absolute;
          bottom: 90px;
          right: 200px;
          cursor: pointer;
        }

        .JL_tipBtn:hover {
          animation: header_fourMove 0.2s linear infinite; // 重点，定义动画
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          @keyframes header_fourMove {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-2px);
            }
          }
        }
      }

      .Tip12 {
        position: relative;

        .JL_tip {

        }

        .tipBtn {
          position: absolute;
          bottom: 70px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          img:hover {
            cursor: pointer;
            animation: header_fourMove 0.2s linear infinite; // 重点，定义动画
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
            @keyframes header_fourMove {
              0% {
                transform: translateY(0);
              }
              100% {
                transform: translateY(-2px);
              }
            }
          }
        }

      }

      .first_content {
        width: 1120px;
        margin-bottom: 9px;
      }

      .second_content {
        width: 1120px;
        margin-bottom: 9px;
      }

      .third_content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .third_content_item {
          margin-bottom: 50px;

          .content_item_title {
            display: flex;
            flex-direction: row;
            margin-bottom: 21px;

            div:first-of-type {
              margin-right: 20px;
            }

            div:last-of-type {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FF9407;
            }
          }

          .content_item_content {
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 25px;

            img {
              width: 1120px;
              height: 128px;
              object-fit: cover;
            }
          }
        }

        .third_content_item:last-of-type {
          margin-bottom: 0;
        }

        .third_content_item:first-of-type {
          margin-bottom: 41px;
        }
      }

      .fourth_content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        .fourth_content_item {
          width: 50%;

          .fourth_content_item_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 15px 0;

            div:first-of-type {
              margin-right: 20px;
            }

            .qq_logo {
              margin-right: 10px;
            }

            .group_name {
              font-size: 20px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #000000;
              line-height: 28px;
              margin-right: 30px;
            }

            .add_group {
              width: 100px;
              height: 40px;
              background: linear-gradient(180deg, #FFDB51 0%, #FF8435 100%);
              border-radius: 20px;
              font-size: 20px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 40px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .showFalse {
    width: 300px;
    //height: 285px;
    background: #FFFFFF;
    padding: 50px 450px 70px;

    .showFalse_tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 39px 0;

      img {
        //width: 229px;
        //height: 150px;
        object-fit: cover;
        //margin: 0 0 6px 0;
      }

      #tip {
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #9A9A9A;
        line-height: 20px;
      }
    }

    .showFalse_btnG {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        width: 300px;
        height: 50px;
        border-radius: 8px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      #back {
        background: #434ED6;
        margin: 0 0 8px 0;
      }

      .cusSer {
        color: #000000;
        border: 1px solid #000000;
      }
    }
  }

}

</style>
